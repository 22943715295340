var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"send-log-message-action"},[_c('a-alert',{attrs:{"description":_vm.$t('trigger_action_register_alert')}}),_c('select-input',{attrs:{"setup":{
      'func': _vm.triggerInputSetupFn,
      'args': {
        model: _vm.model,
        'key': 'eventId',
        'prefix': 'trigger_action_',
        'options': _vm.configEventOptions,
        disabled: _vm.disabled,
        'validation': 'required',
        'clearable': false,
      }
    }}}),_c('number-input',{attrs:{"setup":{
      'func': _vm.triggerInputSetupFn,
      'args': {
        model: _vm.model,
        'key': 'weight',
        'prefix': 'trigger_action_',
        'validation': 'required',
        'min': Number.MIN_SAFE_INTEGER,
        disabled: _vm.disabled,
      }
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }