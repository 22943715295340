import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { baseCondition } from "@/components/TriggerSetup/logic/structures/base-condition.structure";
import NumberConditionSetupView
  from "@/components/TriggerSetup/components/NumberConditionSetupView/NumberConditionSetupView.vue";
import { TriggerCondition } from "@/components/TriggerSetup/logic/types/types";
import { snakeCase } from "lodash";
import { getTagsByFieldKey } from '@/mixins/TariffsTagsHelper'

const condition: () => EntityData = () => {
  return {
    'setupView': NumberConditionSetupView,
    'structure': {
      'type': 'UserNameMinLen',
      ...baseCondition,
      ...{ 'value': [ 0 ] }
    } as TriggerCondition,
    'titleKey': `trigger_condition_${ snakeCase('UserNameMinLen') }`,
    'limited': getTagsByFieldKey('UserNameMinLenCondition'),
  }
}

export default condition
