import { getTariffTranslationKey } from '@/includes/helpers/tariffHelper'
import i18n from '@/i18n/i18n'
import store from '@/store/store'

import { TagData } from 'piramis-base-components/src/components/Tags/types'

import { Vue } from 'vue-property-decorator'

export function getTagsByFieldKey(key: string): Array<TagData> | null {
    if (!store.state.userState.currentUserConfig?.limits.options.includes(key)) {
        return [ {
            'text': i18n.t(getTariffTranslationKey(Vue.prototype.$getOptionTariff(key))) as string,
            'color': getTariff(i18n.t(getTariffTranslationKey(Vue.prototype.$getOptionTariff(key))).toString()).color
        } ]
    }
    return null
}

function getTariff(key: string): { img: string, color: string } {
    const tariffData = (store.state as any).AdminTemplate.applicationConfig.serviceData.tariffs[key.toLowerCase()]

    if (key && tariffData) {
        return tariffData
    } else {
        return {
            img: '',
            color: ''
        }
    }
}