var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('select-input',{attrs:{"setup":{
    'func': _vm.modelSetter,
    'args': {
      model: _vm.model,
      'key': 'targetType',
      'prefix': 'trigger_action_',
      'options': _vm.calculatedTargetTypes,
      disabled: _vm.disabled,
      'validation': 'required',
      'clearable': false,
    }
  }}})}
var staticRenderFns = []

export { render, staticRenderFns }