var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"condition-invert-settings"},[_c('switch-input',{attrs:{"setup":{
      'func': _vm.conditionInputSetupFn,
      'args': {
        'model': _vm.conditionData,
        'key': 'invert',
        'prefix': 'trigger_condition_',
        'disabled': _vm.disabled
      }
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }