import { EntityData } from '@/components/TriggerSetup/logic/types/entity-data.type'
import { baseCondition } from '@/components/TriggerSetup/logic/structures/base-condition.structure'
import { TriggerCondition } from '@/components/TriggerSetup/logic/types/types'
import { snakeCase } from 'lodash'
import UserRights from './UserRights.vue'
import { getTagsByFieldKey } from '@/mixins/TariffsTagsHelper'
import i18n from '@/i18n/i18n'

const condition: () => EntityData = () => {
    return {
        'setupView': UserRights,
        'structure': {
            'type': 'UserRights',
            ...baseCondition,
            ...{ 'value': [ 'User' ] }
        } as TriggerCondition,
        'titleKey': `trigger_condition_${ snakeCase('UserRights') }`,
        'limited': getTagsByFieldKey('UserRightsCondition'),
    }
}
export default condition
