var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"send-log-message-action"},[_c('a-alert',{attrs:{"description":_vm.$t('send_log_message_action_target_alert')}}),_c('action-text',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}),_c('text-input',{attrs:{"setup":{
      'func': _vm.triggerInputSetupFn,
      'args': {
        model: _vm.model,
        'prefix': 'send_log_message_action_',
        'key': 'target',
        disabled: _vm.disabled,
      }
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }