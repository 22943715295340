var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"forward-message-to-target-action"},[_c('forward-message-actions-warning'),_c('text-input',{attrs:{"setup":{
      'func': _vm.triggerInputSetupFn,
      'args': {
        model: _vm.model,
        disabled: _vm.disabled,
        'key': 'target',
        'prefix': 'trigger_action_receive_',
        'validation': 'required',
      }
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }