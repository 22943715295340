



















import TriggerSetup from '@/components/TriggerSetup/TriggerSetup.vue'
import { RawTriggerData, TriggerData } from '@/components/TriggerSetup/logic/types/types'
import { TriggerFactory } from '@/components/TriggerSetup/logic/factories/trigger-factory'
import TriggerControls from '@/components/TriggerSetup/components/TriggerControls/TriggerControls.vue'
import TriggerService from '@/includes/services/TriggerService'
import { errorNotification, successNotification } from '@/includes/services/NotificationService'

import { UseFields } from 'piramis-base-components/src/components/Pi/index'

import { Component, Mixins } from 'vue-property-decorator'
import { cloneDeep } from 'lodash'

enum TriggerSetupType {
  New = 'new',
  Watch = 'watch',
  Edit = 'edit',
}

@Component({
  'components': {
    TriggerSetup,
  },

  'data': () => ({
    TriggerControls,
  })
})
export default class NewTrigger extends Mixins<UseFields>(UseFields) {
  triggerModel: TriggerData | null = null

  isLoading = false

  triggerFactory: TriggerFactory = new TriggerFactory()

  isTriggerSetupDisabled = false

  get userId(): number {
    return Number.parseInt(this.$route.params.id)
  }

  get triggerKey(): string {
    return this.$route.query.triggerKey.toString()
  }

  get triggerSetupType(): TriggerSetupType {
    return this.$route.params.actionType as TriggerSetupType
  }

  get controlsListeners(): any {
    const listeners: any = {}

    if (!this.isTriggerSetupDisabled) {
      listeners['save'] = this.handleSave
      listeners['save-no-move'] = this.handleSaveNotMove

      if (this.triggerSetupType === TriggerSetupType.New) {
        listeners['reset'] = this.handleReset
      }

      if (this.triggerSetupType === TriggerSetupType.Edit) {
        listeners['remove'] = this.handleRemove
      }
    }

    if (this.isTriggerSetupDisabled) {
      listeners['copy'] = this.handleCopy
      listeners['back'] = this.handleBack
    }
    return listeners
  }

  handleBack(): void {
    this.$router.back()
  }

  async setTrigger(rawTriggerData: RawTriggerData): Promise<void> {
    return TriggerService.setTrigger('tg', {
      user_key: this.$store.getters.getCurrentUser(this.$route.params.id).key,
      trigger: rawTriggerData,
      trigger_key: this.triggerKey
    })
      .then(() => successNotification())
      .catch(errorNotification)
  }

  async createTrigger(rawTriggerData: RawTriggerData): Promise<any> {
    return TriggerService.createTrigger('tg', {
      user_key: this.$store.getters.getCurrentUser(this.$route.params.id).key,
      trigger: rawTriggerData,
    })
      .then(response => {
        this.$store.commit('ADD_ACTIVE_TRIGGER_ID', response.key)
        successNotification()
        return response
      })
      .catch(errorNotification)
  }

  async handleSaveNotMove(rawTriggerData: RawTriggerData): Promise<void> {
    try {
      this.isLoading = true

      if (this.triggerSetupType === TriggerSetupType.Edit) {
        await this.setTrigger(rawTriggerData)
      }

      if (this.triggerSetupType === TriggerSetupType.New) {
        const response = await this.createTrigger(rawTriggerData)

        await this.$router.push({
          name: 'Trigger',
          params: {
            id: this.$route.params.id,
            actionType: 'edit',
          },
          query: {
            triggerKey: response.key,
          },
        })
      }

    } catch (error) {
      errorNotification(error)
    } finally {
      this.isLoading = false
    }
  }

  async handleSave(rawTriggerData: RawTriggerData): Promise<void> {
    try {
      this.isLoading = true

      if (this.triggerSetupType === TriggerSetupType.Edit) {
        await this.setTrigger(rawTriggerData)
      }

      if (this.triggerSetupType === TriggerSetupType.New) {
        await this.createTrigger(rawTriggerData)
      }

      this.gotoTriggers()

    } catch (error) {
      errorNotification(error)
    } finally {
      this.isLoading = false
    }
  }

  handleCopy(triggerKey: string): void {
    this.$store.dispatch('MAKE_TRIGGER_COPY', triggerKey)
      .then(() => {
        this.$router.push({
          'name': 'Trigger',
          'params': { 'actionType': TriggerSetupType.New },
        })
      })
  }

  async handleRemove(): Promise<void> {
    try {
      this.isLoading = true
      await TriggerService.deleteTrigger('tg', {
        user_key: this.$store.state.userState.currentUserConfig?.user.key,
        trigger_key: this.triggerKey
      })

      successNotification()

      this.$store.commit('REMOVE_TRIGGER_FROM_ACTIVE', this.triggerKey)

      this.gotoTriggers()

    } catch (error) {
      errorNotification(error)
    } finally {
      this.isLoading = false
    }
  }

  handleReset(): void {
    this.$confirm({
      title: this.$t('restore_trigger').toString(),
      content: this.$t('restore_trigger_text').toString(),
      okText: this.$t('accept').toString(),
      cancelText: this.$t('reject').toString(),
      okType: 'danger',
      centered: true,
      onOk: () => {
        if (this.triggerModel !== null) {
          this.triggerModel = this.triggerFactory.createNewTrigger()
        }
      }
    })
  }

  gotoTriggers(): void {
    this.$router.push({
      name: 'Triggers',
      params: { id: this.$route.params.id },
    })
  }

  async created(): Promise<void> {
    const triggerFactory: TriggerFactory = this.triggerFactory

    const createNewTriggerFromExists = async ({ trigger_key }: { trigger_key: string }): Promise<TriggerData | null> => {
      const trigger = await TriggerService.getTrigger('tg', {
        user_key: this.$store.getters.getCurrentUser(this.$route.params.id).key,
        trigger_key: trigger_key
      })
      return triggerFactory.createNewTriggerFromExists((trigger.config as any))
    }

    if (this.triggerSetupType === TriggerSetupType.New) {
      if (this.$store.state.triggerState.triggerCopy) {
        this.triggerModel = cloneDeep(this.$store.state.triggerState.triggerCopy)
        this.$store.commit('SET_TRIGGER_COPY', null)
      } else {
        this.triggerModel = triggerFactory.createNewTrigger()
      }
    }

    if (this.triggerSetupType === TriggerSetupType.Edit) {
      if (this.triggerKey) {
        this.triggerModel = await createNewTriggerFromExists({ 'trigger_key': this.triggerKey }) || null
      }
    }

    if (this.triggerSetupType === TriggerSetupType.Watch) {
      if (this.triggerKey) {
        this.triggerModel = await createNewTriggerFromExists({ 'trigger_key': this.triggerKey }) || null
        this.isTriggerSetupDisabled = true
      }
    }
  }
}
