











import Component from "vue-class-component";
import ListConditionSetupView
  from '@/components/TriggerSetup/components/ListConditionSetupView/ListConditionSetupView.vue'
import { Mixins } from 'vue-property-decorator'
import { ConditionSetupView } from '@/components/TriggerSetup/logic/mixins/condition-setup-view.mixin'

@Component({
  components: { ListConditionSetupView }
})
export default class MessageTextModificationsMatchSetupView extends Mixins<ConditionSetupView>(ConditionSetupView) {

}
