import { EntityData } from '@/components/TriggerSetup/logic/types/entity-data.type'
import { baseCondition } from '@/components/TriggerSetup/logic/structures/base-condition.structure'
import MessageTypeConditionSetupView
    from '@/components/TriggerSetup/components/MessageTypeConditionSetupView/MessageTypeConditionSetupView.vue'
import { TriggerCondition } from '@/components/TriggerSetup/logic/types/types'
import { snakeCase } from 'lodash'
import { getTagsByFieldKey } from '@/mixins/TariffsTagsHelper'
import i18n from '@/i18n/i18n'

const condition: () => EntityData = () => {
    return {
        'setupView': MessageTypeConditionSetupView,
        'structure': {
            'type': 'MessageType',
            ...baseCondition,
        } as TriggerCondition,
        'titleKey': `trigger_condition_${ snakeCase('MessageType') }`,
        'limited': getTagsByFieldKey('MessageTypeCondition'),
    }
}

export default condition
