






































































































import { IterableListItem, TriggerCondition } from '@/components/TriggerSetup/logic/types/types'
import { TriggerFactory } from '@/components/TriggerSetup/logic/factories/trigger-factory'
import CreateCondition from '@/components/TriggerSetup/components/CreateCondition/CreateCondition.vue'
import { EntityData } from '@/components/TriggerSetup/logic/types/entity-data.type'
import { TriggerHelper } from '@/components/TriggerSetup/logic/mixins/mixins'

import ConfigGroup from 'piramis-base-components/src/components/ConfigGroup/ConfigGroup.vue'
import BlockMovingControls from 'piramis-base-components/src/components/BlockMovingControls/BlockMovingControls.vue'
import List from 'piramis-base-components/src/components/Pi/components/List/List.vue'
import { ListDirection } from 'piramis-base-components/src/components/Pi/components/List/logic/types'

import { Component, Mixins, Prop, VModel } from 'vue-property-decorator'
import { snakeCase } from 'lodash'

@Component({
  'components': {
    CreateCondition,
    ConfigGroup,
    BlockMovingControls,
    List,
  },
  data() {
    return {
      ListDirection,
    }
  },
  'methods': {
    snakeCase
  }
})
export default class ConditionsGroups extends Mixins<TriggerHelper>(TriggerHelper) {
  @VModel({
    'type': Array,
    'required': true
  }) model!: Array<IterableListItem<Array<IterableListItem<TriggerCondition>>>>

  @Prop({ 'type': Boolean, 'default': false }) readonly disabled!: boolean

  @Prop() filteredConditions!: Array<{ values: Record<string, EntityData>, groupName?: string }>

  triggerFactory: TriggerFactory = new TriggerFactory()

  createCondition(conditionGroup: IterableListItem<Array<IterableListItem<TriggerCondition>>>, structure: TriggerCondition): void {
    conditionGroup.value.push(this.triggerFactory.createCondition(structure))
  }

  addConditionsGroup(conditionsGroups: Array<IterableListItem<Array<IterableListItem<TriggerCondition>>>>): void {
    conditionsGroups.push(this.triggerFactory.createConditionsGroup())
  }
}
