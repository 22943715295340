var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"conditions-groups"},[_c('list',{attrs:{"config":{
      'data': _vm.model,
      'keyFn': function (item) { return item.guid; },
      'direction': _vm.ListDirection.Vertical
    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var item = ref.item;
    var index = ref.index;
return [_c('a-card',{staticClass:"trigger-card"},[(!_vm.disabled)?_c('template',{slot:"title"},[_c('block-moving-controls',{attrs:{"vertical":true,"position":index,"disabled":_vm.disabled},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1):_vm._e(),(!_vm.disabled)?_c('template',{slot:"extra"},[_c('a-icon',{staticClass:"trigger-card__remove text-danger",attrs:{"type":"delete","disabled":_vm.disabled},on:{"click":function($event){_vm.model = _vm.model.filter(function (filteredItem, filteredIndex) { return filteredIndex !== index; })}}})],1):_vm._e(),_c('div',[_c('list',{attrs:{"config":{
            'data': item.value,
            'keyFn': function (item) { return item.guid; },
            'direction': _vm.ListDirection.Vertical
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var index = ref.index;
return [_c('a-card',{staticClass:"trigger-card mt-5"},[(!_vm.disabled)?_c('template',{slot:"title"},[_c('block-moving-controls',{attrs:{"vertical":true,"position":index},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}})],1):_vm._e(),(!_vm.disabled)?_c('div',{attrs:{"slot":"extra"},slot:"extra"},[_c('a-icon',{staticClass:"trigger-card__remove text-danger",attrs:{"type":"delete"},on:{"click":function($event){return item.value.splice(index, 1)}}})],1):_vm._e(),_c('div',[_c('div',{staticClass:"text-lg font-semibold p-2"},[_vm._v(" "+_vm._s(_vm.$t(("trigger_condition_" + (_vm.snakeCase(item.value[index].value.type)))))+" ")]),_c(_vm.componentView(item.value[index].value.type, _vm.filteredConditions),{tag:"component",attrs:{"disabled":_vm.disabled},model:{value:(item.value[index].value),callback:function ($$v) {_vm.$set(item.value[index], "value", $$v)},expression:"item.value[index].value"}})],1)],2)]}}],null,true)}),(!_vm.disabled)?_c('div',{staticClass:"flex flex-row item-center justify-center p-2 mt-2"},[_c('create-condition',{attrs:{"conditions":_vm.filteredConditions},on:{"select-condition":function (structure) { return _vm.createCondition(item, structure); }}})],1):_vm._e()],1)],2)]}}])}),(!_vm.disabled)?_c('div',{staticClass:"flex items-center justify-center mt-4"},[_c('a-button',{attrs:{"type":"primary","disabled":_vm.disabled},on:{"click":function($event){return _vm.addConditionsGroup(_vm.model)}}},[_vm._v(" "+_vm._s(_vm.$t('trigger_setup_button_add_condition_group'))+" ")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }