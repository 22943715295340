



















import { ConditionSetupView } from '@/components/TriggerSetup/logic/mixins/condition-setup-view.mixin'
import ConditionInvertSettings
  from '@/components/TriggerSetup/components/ConditionInvertSettings/ConditionInvertSettings.vue'
import UserRightsTypes from '@/components/TriggerSetup/conditions/UserRights/UserRights.vue'

import { SelectOption } from 'piramis-base-components/src/logic/types'

import { Component, Mixins, Prop } from 'vue-property-decorator'

@Component({
  components: { ConditionInvertSettings }
})
export default class ConditionUserRights extends Mixins<ConditionSetupView>(ConditionSetupView) {
  @Prop({ type: Array }) acceptedUserRights!: Array<UserRightsTypes>

  get userRights(): Array<SelectOption> {
    if (this.acceptedUserRights && this.acceptedUserRights.length) {
      return this.userRightsOptions.filter(right => this.acceptedUserRights.includes(right.value))
    }

    return this.userRightsOptions
  }
}
