import { render, staticRenderFns } from "./ActionChance.vue?vue&type=template&id=9967ed92&scoped=true&"
import script from "./ActionChance.vue?vue&type=script&lang=ts&"
export * from "./ActionChance.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9967ed92",
  null
  
)

export default component.exports