import { PlaceholdersType } from "piramis-base-components/src/components/Editor/components/Toolbar/types";
import i18n from '@/i18n/i18n'

export const BASE_PLACEHOLDERS = {
    type: PlaceholdersType.Stack,
    placeholders: [
        {
            sections: [
                {
                    buttonTitle: i18n.t('placeholder_group_message_info').toString(),
                    placeholders: [
                        {
                            label: i18n.t('placeholder_message').toString(),
                            value: '%message%'
                        },
                        {
                            label: i18n.t('placeholder_reply_message').toString(),
                            value: '%reply_message%'
                        },
                        {
                            label: i18n.t('placeholder_message_link').toString(),
                            value: '%message_link%'
                        },
                        {
                            label: i18n.t('placeholder_reply_message_link').toString(),
                            value: '%reply_message_link%'
                        },
                    ]
                },
                {
                    buttonTitle: i18n.t('placeholder_group_user_info').toString(),
                    placeholders: [
                        {
                            label: i18n.t('placeholder_profile').toString(),
                            value: '%profile%'
                        },
                        {
                            label: i18n.t('placeholder_user').toString(),
                            value: '%user%'
                        },
                        {
                            label: i18n.t('placeholder_reply_user').toString(),
                            value: '%reply_user%'
                        },
                    ]
                },
                {
                    buttonTitle: i18n.t('placeholder_group_trigger_info').toString(),
                    placeholders: [
                        {
                            label: i18n.t('placeholder_condition').toString(),
                            value: '%condition%'
                        },
                        {
                            label: i18n.t('placeholder_trigger').toString(),
                            value: '%trigger%'
                        },
                        {
                            label: i18n.t('placeholder_chat').toString(),
                            value: '%chat%'
                        },
                        {
                            label: i18n.t('placeholder_time').toString(),
                            value: '%time%'
                        }
                    ]
                }
            ]
        },
    ]
}
