

























import Component from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'
import { ConditionSetupView } from '@/components/TriggerSetup/logic/mixins/condition-setup-view.mixin'
import ConditionInvertSettings from '../../components/ConditionInvertSettings/ConditionInvertSettings.vue'

@Component({
  components: {
    ConditionInvertSettings
  }
})
export default class MessageReplyStateView extends Mixins<ConditionSetupView>(ConditionSetupView) {

}
