import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { baseCondition } from "@/components/TriggerSetup/logic/structures/base-condition.structure";
import { TriggerCondition } from "@/components/TriggerSetup/logic/types/types";
import { snakeCase } from "lodash";
import { getTagsByFieldKey } from '@/mixins/TariffsTagsHelper'
import i18n from '@/i18n/i18n'
import MessageTextRegExpSetupView
  from '@/components/TriggerSetup/conditions/MessageTextRegExp/MessageTextRegExpSetupView.vue'

const condition: () => EntityData = () => {
  return {
    'setupView': MessageTextRegExpSetupView,
    'structure': {
      'type': 'MessageTextRegExp',
      ...baseCondition,
    } as TriggerCondition,
    'titleKey': `trigger_condition_${ snakeCase('MessageTextRegExp') }`,
    'limited': getTagsByFieldKey('MessageTextRegExpCondition'),
    'helpMessage': i18n.t('regexp_condition_help_message').toString()
  }
}

export default condition
