import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { baseCondition } from "@/components/TriggerSetup/logic/structures/base-condition.structure";
import { TriggerCondition } from "@/components/TriggerSetup/logic/types/types";
import { snakeCase } from "lodash";
import ReplyUserRights from './ReplyUserRights.vue'
import { getTagsByFieldKey } from '@/mixins/TariffsTagsHelper'

const condition: () => EntityData = () => {
  return {
    'setupView': ReplyUserRights,
    'structure': {
      'type': 'ReplyUserRights',
      ...baseCondition,
      ...{ 'value': [ 'User' ] }
    } as TriggerCondition,
    'titleKey': `trigger_condition_${ snakeCase('ReplyUserRights') }`,
    'limited': getTagsByFieldKey('ReplyUserRightsCondition'),
  }
}

export default condition
