var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"work-days-setup-view"},[_c('range-input',{attrs:{"setup":{
      'func': _vm.conditionInputSetupFn,
      'args': {
        'model': _vm.conditionData,
        'key': 'value',
        'prefix': 'work_days_',
        'validation': 'required',
        'disabled': _vm.disabled,
        'min': 1,
        'max': 31,
      }
    }}}),_c('condition-invert-settings',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.conditionData),callback:function ($$v) {_vm.conditionData=$$v},expression:"conditionData"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }