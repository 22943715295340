

















import { Component, Mixins } from 'vue-property-decorator'
import { ActionSetupView } from "@/components/TriggerSetup/logic/mixins/action-setup-view.mixin";
import { InputSetups } from '@/mixins/input-setups'

@Component
export default class ActionCalculatedTarget extends Mixins<InputSetups, ActionSetupView>(InputSetups, ActionSetupView) {

}
