import { Component, Vue } from 'vue-property-decorator'
import { FieldData, SelectOptionData } from 'piramis-base-components/src/components/Pi/types'
import { EntityData } from '@/components/TriggerSetup/logic/types/entity-data.type'
import { UserRightsTypes } from '@/includes/types/UserRightsEnum'

@Component
export class TriggerHelper extends Vue {
    get workspaceOptions(): Array<SelectOptionData> {
        return [
            {
                label: this.$t('trigger_workspace_group').toString(),
                value: 'Group',
            }, {
                label: this.$t('trigger_workspace_channel').toString(),
                value: 'Channel',
            }, {
                label: this.$t('trigger_workspace_privat_messages').toString(),
                value: 'PrivatMessages',
            }
        ]
    }

    get actionExecuteType(): Array<SelectOptionData> {
        return [
            {
                label: this.$t('trigger_action_execute_type_all').toString(),
                value: 'All',
            }, {
                label: this.$t('trigger_action_execute_type_random').toString(),
                value: 'Random',
            },
        ]
    }

    get restrictTypes(): Array<SelectOptionData> {
        return [
            {
                label: this.$t('trigger_action_restrict_type_media').toString(),
                value: 'Media',
            }, {
                label: this.$t('trigger_action_restrict_type_inline').toString(),
                value: 'Inline',
            }, {
                label: this.$t('trigger_action_restrict_type_invite').toString(),
                value: 'Invite',
            }, {
                label: this.$t('trigger_action_restrict_type_pool').toString(),
                value: 'Pool',
            },
        ]
    }

    get asReplyTypes(): Array<SelectOptionData> {
        return [
            {
                label: this.$t('trigger_action_as_reply_types_none').toString(),
                value: 'None',
            }, {
                label: this.$t('trigger_action_as_reply_types_reply').toString(),
                value: 'Reply',
            }, {
                label: this.$t('trigger_action_as_reply_types_reply_to_reply').toString(),
                value: 'ReplyToReply',
            },
        ]
    }

    get getCalculatedTargetTypes(): Array<SelectOptionData> {
        return [
            {
                label: this.$t('send_message_to_calculated_target_action_all').toString(),
                value: 'All',
            },
            {
                label: this.$t('send_message_to_calculated_target_action_reply_message_author').toString(),
                value: 'ReplyMessageAuthor',
            },
            {
                label: this.$t('send_message_to_calculated_target_action_message_author').toString(),
                value: 'MessageAuthor',
            },
        ]

    }

    get messageTypeValues(): Array<SelectOptionData> {
        return [
            {
                label: this.$t('trigger_condition_message_type_audio').toString(),
                value: 'Audio',
            }, {
                label: this.$t('trigger_condition_message_type_document').toString(),
                value: 'Document',
            }, {
                label: this.$t('trigger_condition_message_type_sticker').toString(),
                value: 'Sticker',
            }, {
                label: this.$t('trigger_condition_message_type_photo').toString(),
                value: 'Photo',
            }, {
                label: this.$t('trigger_condition_message_type_photo_file').toString(),
                value: 'PhotoFile',
            }, {
                label: this.$t('trigger_condition_message_type_video').toString(),
                value: 'Video',
            }, {
                label: this.$t('trigger_condition_message_type_video_file').toString(),
                value: 'VideoFile',
            }, {
                label: this.$t('trigger_condition_message_type_animation').toString(),
                value: 'Animation',
            }, {
                label: this.$t('trigger_condition_message_type_contact').toString(),
                value: 'Contact',
            }, {
                label: this.$t('trigger_condition_message_type_location').toString(),
                value: 'Location',
            },
            {
                label: this.$t('trigger_condition_message_type_voice').toString(),
                value: 'Voice',
            }, {
                label: this.$t('trigger_condition_message_type_game').toString(),
                value: 'Game',
            },
            {
                label: this.$t('trigger_condition_message_type_video_note').toString(),
                value: 'VideoNote',
            },
            {
                label: this.$t('trigger_condition_message_type_poll').toString(),
                value: 'Poll',
            },
        ]
    }

    get conditionFilters(): Array<SelectOptionData> {
        return [
            {
                label: this.$t('trigger_condition_case_insensitive').toString(),
                value: 'CaseInsensitive',
            }, {
                label: this.$t('trigger_condition_remove_spaces').toString(),
                value: 'RemoveSpaces',
            }, {
                label: this.$t('trigger_condition_remove_symbols').toString(),
                value: 'RemoveSymbols',
            }, {
                label: this.$t('trigger_condition_remove_digits').toString(),
                value: 'RemoveDigits',
            },
        ]
    }

    getActionTarget(type: string): Array<SelectOptionData> {
        return [
            {
                label: this.$t('trigger_action_target_caller_and_reply').toString(),
                value: 'CallerAndReply',
            }, {
                label: this.$t('trigger_action_target_caller').toString(),
                value: 'Caller',
            }, {
                label: this.$t('trigger_action_target_reply').toString(),
                value: 'Reply',
            },
        ]
    }

    get replyStateOptions(): Array<SelectOptionData> {
        return [
            {
                label: this.$t('trigger_condition_reply_state_replies').toString(),
                value: 'Replies',
            }, {
                label: this.$t('trigger_condition_reply_state_no_replies').toString(),
                value: 'NoReplies',
            }, {
                label: this.$t('trigger_condition_reply_state_channel_comment').toString(),
                value: 'ChannelComment',
            },
        ]
    }

    get userRightsOptions(): Array<SelectOptionData> {
        return [
            {
                label: this.$t('trigger_condition_user_right_admin').toString(),
                value: UserRightsTypes.Admin,
            }, {
                label: this.$t('trigger_condition_user_right_user').toString(),
                value: UserRightsTypes.User,
            }, {
                label: this.$t('trigger_condition_user_right_creator').toString(),
                value: UserRightsTypes.Creator,
            }, {
                label: this.$t('trigger_condition_user_right_bot').toString(),
                value: UserRightsTypes.Bot,
            }, {
                label: this.$t('trigger_condition_user_right_binded_channel').toString(),
                value: UserRightsTypes.BindedChannel,
            }, {
                label: this.$t('trigger_condition_user_right_channel').toString(),
                value: UserRightsTypes.Channel,
            }, {
                label: this.$t('trigger_condition_user_right_anonymous').toString(),
                value: UserRightsTypes.Anonymous,
            },
        ]
    }

    getActionExecuteTypeOptions(): Promise<Array<SelectOptionData>> {
        return Promise.resolve(this.actionExecuteType)
    }

    getTriggerWorkspaceOptions(): Promise<Array<SelectOptionData>> {
        return Promise.resolve(this.workspaceOptions)
    }

    componentView(type: string, dataToSearch: Array<{ values: Record<string, EntityData>, groupName?: string }>): any {
        let setupView: any = null

        for (const li of dataToSearch) {
            for (const groupValue of Object.values(li.values)) {
                if (groupValue.structure.type === type) {
                    setupView = groupValue.setupView
                    return setupView
                }
            }
        }
        return setupView
    }

}

@Component
export class TriggerInputsSetups extends Vue {
    triggerInputSetupFn(args: FieldData): FieldData {
        if (args.setter === undefined) {
            args.setter = (value: any): void => {
                this.$store.commit('pi/EXEC', {
                    'key': args.key,
                    'fn': () => {
                        this.$set(args.model, args.key.toString(), value)
                    },
                })
            }
        }

        return args
    }

    conditionInputSetupFn(args: FieldData): FieldData {
        return this.triggerInputSetupFn(args)
    }
}
