




























import { EntityData } from '@/components/TriggerSetup/logic/types/entity-data.type'
import { TriggerHelper } from '@/components/TriggerSetup/logic/mixins/mixins'

import DefaultList from 'piramis-base-components/src/components/Pi/components/DefaultList/DefaultList.vue'
import { ListDirection } from 'piramis-base-components/src/components/Pi/components/List/logic/types'
import { UseFields } from 'piramis-base-components/src/components/Pi/index'
import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'
import Tags from 'piramis-base-components/src/components/Tags/Tags.vue'
import {
  Entity,
  Item,
  ItemIcon
} from 'piramis-base-components/src/components/Pi/components/DefaultList/logic/types'

import { Component, Emit, Mixins, Prop } from 'vue-property-decorator'
import { snakeCase } from 'lodash'
import { Guid } from 'guid-typescript'

@Component({
  'components': {
    DefaultList,
    ConfigField,
    Tags
  },
  data() {
    return {
      ListDirection,
    }
  },
  'methods': {
    snakeCase
  }
})
export default class EntitySelect extends Mixins(UseFields, TriggerHelper) {
  @Prop({ 'required': true }) readonly entitiesConfig!: { entities: Array<{ values: Record<string, EntityData>, groupName?: string }>, groupsTypes: { [key: string]: Array<string> }, entitiesIcons: { [key: string]: ItemIcon } }

  @Prop({ 'type': String, 'required': true, }) readonly buttonTitle!: string

  @Prop({ 'type': String, 'required': true, }) readonly popupTitle!: string

  @Prop({ 'type': Boolean, 'default': false }) readonly disabled!: boolean

  isPopupOpen = false

  get icons() {
    return this.entitiesConfig.entitiesIcons
  }

  get groupsTypes(): { [key: string]: Array<string> } {
    return this.entitiesConfig.groupsTypes
  }

  getConfig(list: Array<{ values: Record<string, EntityData>, groupName?: string }>): Array<Entity> {
    const entitiesList: Array<Entity> = []

    list.forEach(li => {

      let listItem: Entity = {
        group: {
          guid: Guid.create().toString(),
          titleKey: li.groupName ? `trigger_group_${ snakeCase(li.groupName) }` : '',
          groupItems: []
        },
        items: []
      } as Entity

      Object.entries(li.values).forEach(([ _, groupItemValue ]) => {
        if (li.groupName) {
          delete listItem.items
          listItem.group?.groupItems.push(this.prepareItem(groupItemValue))
        } else {
          delete listItem.group
          listItem.items?.push(this.prepareItem(groupItemValue))
        }
      })

      entitiesList.push(listItem)
    })
    return entitiesList
  }

  prepareItem(item: EntityData): Item {
    return {
      title: this.$t(item.titleKey).toString(),
      key: item.titleKey,
      limited: item.limited as Array<{ text: string, color: string }>,
      helpMessage: this.helpMessage(item),
      icon: this.icons[item.structure.type]
    }
  }

  helpMessage(itemStructure:any):string {
    if (itemStructure.helpMessage) {
      return itemStructure.helpMessage
    } else {
      return this.$te(`${ snakeCase(itemStructure.structure.type) }_help_message`) ? this.$t(`${ snakeCase(itemStructure.structure.type) }_help_message`).toString() : ''
    }
  }

  @Emit()
  select(value: Item): EntityData {
    this.isPopupOpen = false
    let entityFound: EntityData = {} as EntityData

    for (const li of this.entitiesConfig.entities) {
      for (const groupValue of Object.values(li.values)) {
        if (groupValue.titleKey === value.key) {
          entityFound = groupValue
          return entityFound
        }
      }
    }
    return entityFound
  }
}
