

























import { Component, Mixins } from 'vue-property-decorator'
import { ConditionSetupView } from "@/components/TriggerSetup/logic/mixins/condition-setup-view.mixin";
import ConditionFiltersSettings
  from '@/components/TriggerSetup/components/ConditionFiltersSettings/ConditionFiltersSettings.vue'
import ConditionInvertSettings
  from '@/components/TriggerSetup/components/ConditionInvertSettings/ConditionInvertSettings.vue'
import { snakeCase } from 'lodash'

@Component({
  'components': {
    ConditionFiltersSettings,
    ConditionInvertSettings
  }
})
export default class ListConditionSetupView extends Mixins<ConditionSetupView>(ConditionSetupView) {
  get snakeCaseType():string {
    return snakeCase(this.conditionData.type)
  }
}
