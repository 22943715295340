import { EntityData } from '@/components/TriggerSetup/logic/types/entity-data.type'
import { snakeCase } from 'lodash'
import ForwardMessageToTargetActionSetupView
    from '@/components/TriggerSetup/actions/ForwardMessageToTargetAction/ForwardMessageToTargetActionSetupView.vue'
import { getTagsByFieldKey } from '@/mixins/TariffsTagsHelper'

const action: () => EntityData = () => {
    return {
        'setupView': ForwardMessageToTargetActionSetupView,
        'structure': {
            'type': 'ForwardMessageToTargetAction',
            'target': null,
            'chance': 100,
        },
        'titleKey': `trigger_action_${ snakeCase('ForwardMessageToTargetAction') }`,
        'limited': getTagsByFieldKey('ForwardMessageToTargetAction'),
    }
}

export default action
