import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { baseCondition } from "@/components/TriggerSetup/logic/structures/base-condition.structure";
import { TriggerCondition } from "@/components/TriggerSetup/logic/types/types";
import { snakeCase } from "lodash";
import { getTagsByFieldKey } from '@/mixins/TariffsTagsHelper'
import i18n from '@/i18n/i18n'
import MessageWordModificationsMatchSetupView from './MessageWordModificationsMatchSetupView.vue'

const condition: () => EntityData = () => {
  return {
    'setupView': MessageWordModificationsMatchSetupView,
    'structure': {
      'type': 'MessageWordModificationsMatch',
      ...baseCondition,
    } as TriggerCondition,
    'titleKey': `trigger_condition_${ snakeCase('MessageWordModificationsMatch') }`,
    'limited': getTagsByFieldKey('MessageWordModificationsMatchCondition'),
 }
}

export default condition
