























import { Component, Mixins } from 'vue-property-decorator'
import { ConditionSetupView } from "@/components/TriggerSetup/logic/mixins/condition-setup-view.mixin";
import ConditionInvertSettings
  from '@/components/TriggerSetup/components/ConditionInvertSettings/ConditionInvertSettings.vue'

@Component({
  'components': {
    ConditionInvertSettings
  }
})
export default class MessageTypeConditionSetupView extends Mixins<ConditionSetupView>(ConditionSetupView) {
}
