import { render, staticRenderFns } from "./MessageWordModificationsMatchSetupView.vue?vue&type=template&id=a5d46274&scoped=true&"
import script from "./MessageWordModificationsMatchSetupView.vue?vue&type=script&lang=ts&"
export * from "./MessageWordModificationsMatchSetupView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a5d46274",
  null
  
)

export default component.exports