
















import { Component, Mixins } from 'vue-property-decorator'
import { ConditionSetupView } from "@/components/TriggerSetup/logic/mixins/condition-setup-view.mixin";

@Component
export default class ConditionInvertSettings extends Mixins<ConditionSetupView>(ConditionSetupView) {

}
