var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"work-time-setup-view"},[_c('div',{staticClass:"flex flex-col w-full"},[_c('time-picker-input',{attrs:{"setup":{
        'func': _vm.conditionInputSetupFn,
        'args': {
          'model': this,
          'key': 'from',
          'prefix': 'work_time_',
          'validation': 'required',
          'disabled': _vm.disabled,
        }
      }}}),_c('time-picker-input',{attrs:{"setup":{
        'func': _vm.conditionInputSetupFn,
        'args': {
          'model': this,
          'key': 'to',
          'prefix': 'work_time_',
          'validation': 'required',
          'disabled': _vm.disabled,
        }
      }}})],1),_c('condition-invert-settings',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.conditionData),callback:function ($$v) {_vm.conditionData=$$v},expression:"conditionData"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }