var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"condition-filter-settings"},[_c('select-input',{attrs:{"setup":{
      'func': _vm.conditionInputSetupFn,
      'args': {
        'model': _vm.conditionData,
        'key': 'value',
        'prefix': 'trigger_condition_',
        'options': _vm.userRights,
        'multiple': true,
        'clearable': false,
        'disabled': _vm.disabled
      }
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }