var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"actions-groups"},[_c('list',{attrs:{"config":{
      'data': _vm.model,
      'keyFn': function (item) { return item.guid; },
      'direction': _vm.ListDirection.Vertical
    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var item = ref.item;
    var index = ref.index;
return [_c('a-card',{staticClass:"shadow-inner"},[(!_vm.disabled)?_c('template',{slot:"title"},[_c('block-moving-controls',{attrs:{"vertical":true,"position":index},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1):_vm._e(),(!_vm.disabled)?_c('template',{slot:"extra"},[_c('a-icon',{staticClass:"trigger-card__remove text-danger",attrs:{"type":"delete"},on:{"click":function($event){_vm.model = _vm.model.filter(function (filteredItem, filteredIndex) { return filteredIndex !== index; })}}})],1):_vm._e(),_c('div',[_c('nested-content',[(_vm.showFromTo)?[_c('number-input',{attrs:{"setup":{
                'func': _vm.triggerInputSetupFn,
                'args': {
                  'model': item.value,
                  'key': 'from',
                  'prefix': 'trigger_action_group_',
                  'disabled': _vm.disabled,
                  'max': item.value.to
                }
              }}}),_c('number-input',{attrs:{"setup":{
                'func': _vm.triggerInputSetupFn,
                'args': {
                  'model': item.value,
                  'key': 'to',
                  'prefix': 'trigger_action_group_',
                  'disabled': _vm.disabled,
                  'min': item.value.to,
                  'max': 100,
                }
              }}})]:_vm._e(),_c('number-input',{attrs:{"setup":{
              'func': _vm.triggerInputSetupFn,
              'args': {
                'model': item.value,
                'key': 'chance',
                'prefix': 'trigger_action_group_',
                'disabled': _vm.disabled,
                'max': 100
              }
            }}})],2),_c('list',{attrs:{"config":{
            'data': item.value.actions,
            'keyFn': function (item) { return item.guid; },
            'direction': _vm.ListDirection.Vertical
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var index = ref.index;
return [_c('a-card',{staticClass:"trigger-card mt-5"},[(!_vm.disabled)?_c('template',{slot:"title"},[_c('block-moving-controls',{attrs:{"vertical":true,"position":index},model:{value:(item.value.actions),callback:function ($$v) {_vm.$set(item.value, "actions", $$v)},expression:"item.value.actions"}})],1):_vm._e(),(!_vm.disabled)?_c('template',{slot:"extra"},[_c('a-icon',{staticClass:"trigger-card__remove text-danger",attrs:{"type":"delete"},on:{"click":function () {
                  item.value.actions.splice(index, 1)
                  _vm.$emit('input', _vm.model)
                }}})],1):_vm._e(),[_c('div',{staticClass:"text-lg font-semibold p-2"},[_vm._v(" "+_vm._s(_vm.$t(("trigger_action_" + (_vm.snakeCase(item.value.actions[index].value.type)))))+" ")]),_c(_vm.componentView(item.value.actions[index].value.type, _vm.filteredActions),{tag:"component",attrs:{"disabled":_vm.disabled,"has-form":_vm.hasForm},model:{value:(item.value.actions[index].value),callback:function ($$v) {_vm.$set(item.value.actions[index], "value", $$v)},expression:"item.value.actions[index].value"}})]],2)]}}],null,true)}),(!_vm.disabled)?_c('div',{staticClass:"flex flex-row item-center justify-center p-2 mt-2"},[_c('create-action',{attrs:{"actions":_vm.filteredActions},on:{"select-action":function (structure) { return _vm.createAction(item, structure); }}})],1):_vm._e()],1)],2)]}}])}),(!_vm.disabled)?_c('div',{staticClass:"flex items-center justify-center mt-5"},[_c('a-button',{attrs:{"type":"primary","disabled":_vm.disabled},on:{"click":function($event){return _vm.addActionGroup(_vm.model)}}},[_vm._v(" "+_vm._s(_vm.$t('trigger_setup_button_add_action_group'))+" ")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }