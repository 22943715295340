import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { baseCondition } from "@/components/TriggerSetup/logic/structures/base-condition.structure";
import { TriggerCondition } from "@/components/TriggerSetup/logic/types/types";
import { snakeCase } from "lodash";
import { getTagsByFieldKey } from '@/mixins/TariffsTagsHelper'
import i18n from '@/i18n/i18n'
import MessageTextModificationsMatchSetupView
  from './MessageTextModificationsMatchSetupView.vue'

const condition: () => EntityData = () => {
  return {
    'setupView': MessageTextModificationsMatchSetupView,
    'structure': {
      'type': 'MessageTextModificationsMatch',
      ...baseCondition,
    } as TriggerCondition,
    'titleKey': `trigger_condition_${ snakeCase('MessageTextModificationsMatch') }`,
    'limited': getTagsByFieldKey('MessageTextModificationsMatchCondition'),
  }
}

export default condition
