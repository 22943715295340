var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"trigger-settings"},[_c('a-alert',{staticClass:"trigger-settings__limits w-full"},[_c('div',{attrs:{"slot":"message"},slot:"message"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t('triggers_call_limit_warning'))+" ")]),_c('p',[_c('b',[_vm._v(_vm._s(_vm.$t('triggers_call_group_send_message')))]),_vm._v(" "+_vm._s(_vm.groupSendInterval)+" ")]),_c('p',[_c('b',[_vm._v(_vm._s(_vm.$t('triggers_call_send_message')))]),_vm._v(" "+_vm._s(_vm.sendInterval)+" ")])])]),_c('a-card',{staticClass:"mt-5"},[(_vm.controls)?_c('div',{staticClass:"w-full flex flex-col justify-center items-end justify-end"},[_c('div',{staticClass:"header-wrapper"},[_c(_vm.controls.view,_vm._g(_vm._b({tag:"component"},'component',Object.assign({}, {triggerData: _vm.triggerData, isTriggerValid: _vm.isTriggerValid}, _vm.controls.props),false),_vm.controls.listeners)),_c('div',{staticClass:"flex flex-col justify-start items-start mt-2"},[(_vm.checkValidTrigger[0])?_c('div',{staticClass:"flex flex-row items-center justify-center"},[_c('a-icon',{staticClass:"text-danger mr-2",staticStyle:{"margin-left":"-5px"},attrs:{"type":"close"}}),_vm._v(" "+_vm._s(_vm.$t(_vm.checkValidTrigger[0]))+" ")],1):_vm._e()])],1)]):_vm._e(),_c('div',{staticClass:"flex flex-col md:flex-row"},[_c('div',{staticClass:"w-full md:w-1/2"},[_c('text-input',{staticClass:"mx-0",attrs:{"setup":{
            'func': _vm.modelSetter,
            'args': {
              'model': _vm.triggerData,
              'key': 'name',
              'prefix': 'trigger_setup_',
              'validation': 'required',
              'disabled': _vm.isDisabled,
            }
          }}})],1),_c('div',{staticClass:"w-full md:w-1/2"},[_c('select-input',{staticClass:"ml-2 mr-0",attrs:{"setup":{
            'func': _vm.modelSetter,
            'args': {
              'model': _vm.triggerData,
              'key': 'actionExecuteType',
              'prefix': 'trigger_setup_',
              'options': _vm.getActionExecuteTypeOptions,
              'disabled': _vm.isDisabled,
              'clearable': false
            }
          }}})],1)]),_c('accordion',{staticClass:"trigger-settings__extra",attrs:{"close-title":'advanced_settings_close',"open-title":'advanced_settings_open'},model:{value:(_vm.isExtraSettingsOpen),callback:function ($$v) {_vm.isExtraSettingsOpen=$$v},expression:"isExtraSettingsOpen"}},[_c('select-input',{attrs:{"setup":{
          'func': _vm.modelSetter,
          'args': {
            'model': _vm.triggerData,
            'key': 'workspace',
            'prefix': 'trigger_setup_',
            'options': _vm.getTriggerWorkspaceOptions,
            'disabled': _vm.isDisabled,
            'clearable': false
          }
        }}}),_c('number-input',{attrs:{"setup":{
          'func': _vm.modelSetter,
          'args': {
            'model': _vm.triggerData,
            'key': 'chance',
            'prefix': 'trigger_setup_',
            'max': 100,
            'disabled': _vm.isDisabled,
          }
        }}}),_c('switch-input',{attrs:{"setup":{
          'func': _vm.modelSetter,
          'args': {
            'model': _vm.triggerData,
            'key': 'processAlbumsLikeFirstPhoto',
            'prefix': 'trigger_setup_',
            'disabled': _vm.isDisabled,
          }
        }}})],1)],1),_c('div',{staticClass:"flex flex-col md:flex-row mt-4"},[_c('div',{staticClass:"w-full md:w-1/2 flex flex-col mr-2"},[_c('h1',[_vm._v(_vm._s(_vm.$t('trigger_setup_conditions')))]),_c('conditions-groups',{attrs:{"filtered-conditions":_vm.conditions,"disabled":_vm.isDisabled},model:{value:(_vm.conditionsGroups),callback:function ($$v) {_vm.conditionsGroups=$$v},expression:"conditionsGroups"}})],1),_c('div',{staticClass:"w-full md:w-1/2 flex flex-col mt-base md:mt-0 ml-2"},[_c('h1',[_vm._v(_vm._s(_vm.$t('trigger_setup_actions')))]),_c('actions-groups',{attrs:{"disabled":_vm.isDisabled,"filtered-actions":_vm.actions},model:{value:(_vm.actionGroups),callback:function ($$v) {_vm.actionGroups=$$v},expression:"actionGroups"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }