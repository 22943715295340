




















































































































































import { TriggerFactory } from '@/components/TriggerSetup/logic/factories/trigger-factory'
import CreateAction from '@/components/TriggerSetup/components/CreateAction/CreateAction.vue'
import { TriggerHelper, TriggerInputsSetups } from '@/components/TriggerSetup/logic/mixins/mixins'
import { InputSetups } from '@/mixins/input-setups'
import { EntityData } from '@/components/TriggerSetup/logic/types/entity-data.type'

import ConfigGroup from 'piramis-base-components/src/components/ConfigGroup/ConfigGroup.vue'
import BlockMovingControls from 'piramis-base-components/src/components/BlockMovingControls/BlockMovingControls.vue'
import List from 'piramis-base-components/src/components/Pi/components/List/List.vue'
import { ListDirection } from 'piramis-base-components/src/components/Pi/components/List/logic/types'
import { UseFields } from 'piramis-base-components/src/components/Pi/index'
import { MessageEditorWithMediaData } from 'piramis-base-components/src/components/MessageEditorWithMedia/types'

import { snakeCase } from 'lodash'
import { Component, Mixins, Prop, VModel } from 'vue-property-decorator'

@Component({
  'components': {
    CreateAction,
    ConfigGroup,
    BlockMovingControls,
    List,
  },
  data() {
    return {
      ListDirection,
    }
  },
  'methods': {
    snakeCase
  }
})
export default class ActionsGroups extends Mixins<UseFields, InputSetups, TriggerHelper, TriggerInputsSetups>(UseFields, InputSetups, TriggerHelper, TriggerInputsSetups) {
  @VModel({ 'type': Array, 'required': true }) model!: any

  @Prop({
    'type': Array,
    'required': false,
    'default': () => []
  }) selectedFormStruct!: Array<MessageEditorWithMediaData>

  @Prop({ 'type': Boolean, 'default': false }) readonly disabled!: boolean

  @Prop({ 'type': Boolean, 'default': false }) readonly showFromTo!: boolean

  @Prop({ 'type': Boolean, 'required': false, 'default': false }) hasForm!: boolean

  @Prop() filteredActions!: Array<{ values: Record<string, EntityData>, groupName?: string }>

  triggerFactory: TriggerFactory = new TriggerFactory()

  addActionGroup(actionGroups: any): void {
    actionGroups.push(this.triggerFactory.createActionGroup())
    this.$emit('input', this.model)
  }

  createAction(actionGroup: any, structure: any): void {
    actionGroup.value.actions.push(this.triggerFactory.createAction(structure))
    this.$emit('input', this.model)
  }
}
