

















import { ActionSetupView } from '@/components/TriggerSetup/logic/mixins/action-setup-view.mixin'
import ActionText from '@/components/TriggerSetup/components/ActionText/ActionText.vue'
import { InputSetups } from '@/mixins/input-setups'
import { TriggerInputsSetups } from '@/components/TriggerSetup/logic/mixins/mixins'
import ActionCalculatedTarget
  from '@/components/TriggerSetup/components/ActionCalculatedTarget/ActionCalculatedTarget.vue'

import { Component, Mixins } from 'vue-property-decorator'
import SendMessageActionsWarning from '@/components/TriggerSetup/components/SendMessageActionsWarning.vue'

@Component({
  'components': {
    ActionCalculatedTarget,
    ActionText,
    SendMessageActionsWarning
  }
})
export default class SendMessageToCalculatedTargetActionView extends Mixins<TriggerInputsSetups, ActionSetupView, InputSetups>(TriggerInputsSetups, ActionSetupView, InputSetups) {
}
