import { EntityData } from '@/components/TriggerSetup/logic/types/entity-data.type'
import { snakeCase } from 'lodash'
import RegisterEventActionSetupView from '@/components/TriggerSetup/actions/RegisterEventAction/RegisterEventAction.vue'
import i18n from '@/i18n/i18n'
import store from '@/store/store'

const action: () => EntityData = () => {
    return {
        'setupView': RegisterEventActionSetupView,
        'structure': {
            'type': 'RegisterEventAction',
            'chance': 100,
            'eventId': null,
            'weight': 1
        },
        'titleKey': `trigger_action_${ snakeCase('RegisterEventAction') }`,
        helpMessage: i18n.t('register_event_action_help_message', [ store.state.userState.currentUserConfig!.user.key.split('.')[0] ]).toString()
    }
}

export default action
