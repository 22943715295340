




























import { TriggerInputsSetups } from '@/components/TriggerSetup/logic/mixins/mixins'
import { ActionSetupView } from '@/components/TriggerSetup/logic/mixins/action-setup-view.mixin'
import { InputSetups } from '@/mixins/input-setups'
import { BASE_PLACEHOLDERS } from '@/includes/placeholders'
import { PlaceholdersType } from 'piramis-base-components/src/components/Editor/components/Toolbar/types'

import {
  MediaEditorMode,
  MessageEditorWithMediaTab
} from 'piramis-base-components/src/components/MessageEditorWithMedia/types'

import { Component, Mixins } from 'vue-property-decorator'

@Component({
  data() {
    return {
      MediaEditorMode,
      MessageEditorWithMediaTab,
      PlaceholdersType,
      BASE_PLACEHOLDERS,
    }
  }
})
export default class ActionText extends Mixins<ActionSetupView, InputSetups, TriggerInputsSetups>(ActionSetupView, InputSetups, TriggerInputsSetups) {

}
