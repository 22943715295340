























import { Component, Mixins, Prop } from 'vue-property-decorator'
import { ActionSetupView } from "@/components/TriggerSetup/logic/mixins/action-setup-view.mixin";
import ActionChance from "@/components/TriggerSetup/components/ActionChance/ActionChance.vue";
import ActionText from "@/components/TriggerSetup/components/ActionText/ActionText.vue";
import ActionFormButtonAction
  from "@/components/TriggerSetup/components/ActionFormButtonAction/ActionFormButtonAction.vue";
import { TriggerInputsSetups } from '@/components/TriggerSetup/logic/mixins/mixins'

@Component({
  'components': {
    ActionChance,
    ActionText,
    ActionFormButtonAction,
  }
})
export default class SendLogMessageActionSetupView extends Mixins<ActionSetupView, TriggerInputsSetups>(ActionSetupView, TriggerInputsSetups) {
  @Prop({ 'type': Boolean, 'required': false, 'default': false }) hasForm!:boolean

}
