import { EntityData } from '@/components/TriggerSetup/logic/types/entity-data.type'
import { snakeCase } from 'lodash'
import SendMessageToCalculatedTargetActionView
    from '@/components/TriggerSetup/actions/SendMessageToCalculatedTargetAction/SendMessageToCalculatedTargetActionView.vue'
import { getTagsByFieldKey } from '@/mixins/TariffsTagsHelper'

const action: () => EntityData = () => {
    return {
        'setupView': SendMessageToCalculatedTargetActionView,
        'structure': {
            'type': 'SendMessageToCalculatedTargetAction',
            'chance': 100,
            'text': [ {
                'text': '',
                'buttons': [],
                'remove_after': 0,
                'send_after': 0,
                'pin': false,
                'disable_link_preview': false,
                'disable_notify': false,
                'remove_previous': false,
            } ],
            'targetType': 'ReplyMessageAuthor',
        },
        'titleKey': `trigger_action_${ snakeCase('SendMessageToCalculatedTargetAction') }`,
        'limited': getTagsByFieldKey('SendMessageToCalculatedTargetAction'),
    }
}

export default action
