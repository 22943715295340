import { EntityData } from '@/components/TriggerSetup/logic/types/entity-data.type'
import { baseCondition } from '@/components/TriggerSetup/logic/structures/base-condition.structure'
import { TriggerCondition } from '@/components/TriggerSetup/logic/types/types'
import WorkWeekDaysSetupView from '@/components/TriggerSetup/conditions/WorkWeekDays/WorkWeekDaysSetupView.vue'
import { snakeCase } from 'lodash'
import { getTagsByFieldKey } from '@/mixins/TariffsTagsHelper'

const condition: () => EntityData = () => {
    return {
        'setupView': WorkWeekDaysSetupView,
        'structure': {
            'type': 'WorkWeekDays',
            ...baseCondition,
        } as TriggerCondition,
        'titleKey': `trigger_condition_${ snakeCase('WorkWeekDays') }`,
        'limited': getTagsByFieldKey('WorkWeekDaysCondition')
    }
}

export default condition
