<template>
  <a-alert class="h-full">
    <div
      slot="description"
      v-html="$t('forward_message_actions_alert')"
    />
  </a-alert>
</template>

<script>
import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component
export default class ForwardMessageActionsWarning extends Vue{
}
</script>

<style scoped>

</style>