





















import { Component, Mixins } from 'vue-property-decorator'
import { ConditionSetupView } from "@/components/TriggerSetup/logic/mixins/condition-setup-view.mixin";
import ConditionInvertSettings
  from '@/components/TriggerSetup/components/ConditionInvertSettings/ConditionInvertSettings.vue'

@Component({
  'components': {
    ConditionInvertSettings
  }
})
export default class NumberConditionSetupView extends Mixins<ConditionSetupView>(ConditionSetupView) {
  get numberValue(): number {
    return this.conditionData.value[0] || 0
  }

  set numberValue(value: number) {
    this.$set(this.conditionData, 'value', [ value ])
  }

  get fieldPrefix():string {
    const basePrefix = 'trigger_condition_'
    const symbolConditionsPrefix = [ 'MessageTextMinLen', 'MessageTextMaxLen', 'UserLoginMaxLen', 'UserLoginMinLen' ]
    const symbolWithWhiteSpaceConditionsPrefix = [ 'UserNameMaxLen', 'UserNameMinLen' ]

    if (symbolConditionsPrefix.includes(this.conditionData.type)) {
      return basePrefix + 'symbols_'
    }

    if (symbolWithWhiteSpaceConditionsPrefix.includes(this.conditionData.type)) {
      return basePrefix + 'symbols_white_space_'
    }

    return basePrefix
  }
}
