import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { baseCondition } from "@/components/TriggerSetup/logic/structures/base-condition.structure";
import ListConditionSetupView
  from "@/components/TriggerSetup/components/ListConditionSetupView/ListConditionSetupView.vue";
import { TriggerCondition } from "@/components/TriggerSetup/logic/types/types";
import { snakeCase } from "lodash";
import { getTagsByFieldKey } from '@/mixins/TariffsTagsHelper'

const condition: () => EntityData = () => {
  return {
    'setupView': ListConditionSetupView,
    'structure': {
      'type': 'TelegramLinkDescriptionContainsTgLink',
      ...baseCondition,
    } as TriggerCondition,
    'titleKey': `trigger_condition_${ snakeCase('TelegramLinkDescriptionContainsTgLink') }`,
    'limited': getTagsByFieldKey('TelegramLinkDescriptionContainsTgLinkCondition'),
  }
}

export default condition
