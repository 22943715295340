import { EntityData } from '@/components/TriggerSetup/logic/types/entity-data.type'
import { snakeCase } from 'lodash'
import SendMessageToTargetActionSetup
    from '@/components/TriggerSetup/actions/SendMessageToTargetAction/SendMessageToTargetActionSetup.vue'
import { getTagsByFieldKey } from '@/mixins/TariffsTagsHelper'
import i18n from '@/i18n/i18n'

const action: () => EntityData = () => {
    return {
        'setupView': SendMessageToTargetActionSetup,
        'structure': {
            'type': 'SendMessageToTargetAction',
            'formButtonAction': '',
            'text': [ {
                'text': '',
                'buttons': [],
            } ],
            'target': '',
            'chance': 100,
        },
        'titleKey': `trigger_action_${ snakeCase('SendMessageToTargetAction') }`,
        'limited': getTagsByFieldKey('SendMessageToTargetAction'),
    }
}

export default action
