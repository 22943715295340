










import ListConditionSetupView from '../../components/ListConditionSetupView/ListConditionSetupView.vue'

import { Component, Mixins } from 'vue-property-decorator'
import { ConditionSetupView } from '@/components/TriggerSetup/logic/mixins/condition-setup-view.mixin'
import RegexpConditionHelpMessage from '@/components/TriggerSetup/components/RegexpConditionHelpMessage.vue'

@Component({
  components: {
    RegexpConditionHelpMessage,
    ListConditionSetupView
  }
})
export default class MessageTextRegExpSetupView extends Mixins<ConditionSetupView>(ConditionSetupView) {
}
