import { EntityData } from '@/components/TriggerSetup/logic/types/entity-data.type'
import { chain } from 'lodash'
import ForwardMessageToTargetAction from './ForwardMessageToTargetAction'
import ForwardReplyMessageToTargetAction from './ForwardReplyMessageToTargetAction'
import SendMessageToCalculatedTargetAction from './SendMessageToCalculatedTargetAction'
import SendMessageToTargetAction from './SendMessageToTargetAction'
import SendMessageToChatAction from './SendMessageToChatAction'
import SendLogMessageAction from './SendLogMessageAction'
import RegisterEventAction from './RegisterEventAction'
import ForwardReplyMessageToCalculatedTargetAction from './ForwardReplyMessageToCalculatedTargetAction'
import ForwardMessageToCalculatedTargetAction from './ForwardMessageToCalculatedTargetAction'
import Component from 'vue-class-component'
import Vue from 'vue'

@Component
export default class ActionsHelper extends Vue {
    get actions(): Array<{ values: Record<string, EntityData>, groupName?: string }> {
        return [ {
            values: chain([
                SendMessageToChatAction(),
                SendLogMessageAction(),
                RegisterEventAction(),
                SendMessageToTargetAction(),
                SendMessageToCalculatedTargetAction(),
                ForwardReplyMessageToTargetAction(),
                ForwardMessageToTargetAction(),
                // ForwardReplyMessageToCalculatedTargetAction(),
                // ForwardMessageToCalculatedTargetAction(),
            ])
                .keyBy('structure.type')
                .value()
        } ]
    }
}
