















import ListConditionSetupView
  from '@/components/TriggerSetup/components/ListConditionSetupView/ListConditionSetupView.vue'

import { Component, Mixins } from 'vue-property-decorator'
import { ConditionSetupView } from '@/components/TriggerSetup/logic/mixins/condition-setup-view.mixin'

@Component({
  components: {
    ListConditionSetupView
  }
})
export default class MessageTextMentionEntityRegexpTypeSetupView extends Mixins<ConditionSetupView>(ConditionSetupView) {

}
