















import ConditionUserRights from '@/components/TriggerSetup/components/ConditionUserRights/ConditionUserRights.vue'
import ConditionInvertSettings
  from '@/components/TriggerSetup/components/ConditionInvertSettings/ConditionInvertSettings.vue'
import { ConditionSetupView } from '@/components/TriggerSetup/logic/mixins/condition-setup-view.mixin'
import { UserRightsTypes } from '@/includes/types/UserRightsEnum'

import Component from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'

@Component({
  components: {
    ConditionInvertSettings,
    ConditionUserRights
  }
})
export default class MyRightsSetupView extends Mixins<ConditionSetupView>(ConditionSetupView) {
  userRightsValues = [ UserRightsTypes.Creator, UserRightsTypes.Admin, UserRightsTypes.User ]
}
