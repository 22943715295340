import { chain } from 'lodash'
import MessageTextFullMatch from './MessageTextFullMatch'

import MessageTextSubstringMatch from './MessageTextSubstringMatch'
import UserLoginMaxLen from './UserLoginMaxLen'
import UserLoginMinLen from './UserLoginMinLen'
import UserLoginRegExp from './UserLoginRegExp'
import UserNameMaxLen from './UserNameMaxLen'
import UserNameMinLen from './UserNameMinLen'
import WorkWeekDays from './WorkWeekDays'
import WorkDays from './WorkDays'
import WorkMonthDays from './WorkMonthDays'
import WorkDates from './WorkDates'
import UserNameRegExp from './UserNameRegExp'
import MessageTextMaxLen from './MessageTextMaxLen'
import MessageTextMinLen from './MessageTextMinLen'
import { EntityData } from '@/components/TriggerSetup/logic/types/entity-data.type'
import WorkTime from './WorkTime'
import MessageTextStartWith from './MessageTextStartWith'
import MessageTextEndWith from './MessageTextEndWith'
import MessageTextRegExp from './MessageTextRegExp'
import MessageWordFullMatch from './MessageWordFullMatch'
import MessageWordStartWith from './MessageWordStartWith'
import MessageWordEndWith from './MessageWordEndWith'
import MessageType from './MessageType'
import MyRights from './MyRights'
import ReplyUserRights from './ReplyUserRights'
import UserRights from './UserRights'
import MessageReplyState from './MessageReplyState'
import MessageTextModificationsMatch from './MessageTextModificationsMatch'
import MessageWordModificationsMatch from './MessageWordModificationsMatch'
import UserDescriptionSubstringMatch from './UserDescriptionSubstringMatch'
import UserDescriptionContainsLink from './UserDescriptionContainsLink'
import UserDescriptionContainsTgLink from './UserDescriptionContainsTgLink'
import TelegramLinkDescriptionContainsLink from './TelegramLinkDescriptionContainsLink'
import TelegramLinkDescriptionSubstringMatch from './TelegramLinkDescriptionSubstringMatch'
import TelegramLinkDescriptionContainsTgLink from './TelegramLinkDescriptionContainsTgLink'
import SourceIdentify from './SourceIdentify'
import UserLoginFullMatch from './UserLoginFullMatch'
import UserNameSubstringMatch from './UserNameSubstringMatch'
import UserNameFullMatch from './UserNameFullMatch'
import MessageTextMentionEntityRegexpType from './MessageTextMentionEntityRegexpType'
import MessageMentionEntityRegexpType from './MessageMentionEntityRegexpType'
import MessagePhoneNumberEntityRegexpType from './MessagePhoneNumberEntityRegexpType'
import TelegramLinks from './TelegramLinks'
import Links from './Links'
import SourceTitle from './SourceTitle'
import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class ConditionsHelper extends Vue {
    get conditions(): Array<{ values: Record<string, EntityData>, groupName?: string }> {
        return [
            {
                groupName: 'triggerWorkspace',
                values: chain([
                    SourceIdentify(),
                    SourceTitle(),
                ]).keyBy('structure.type').value()
            },
            {
                groupName: 'conditionsByMessage',
                values: chain([
                    MessageTextMinLen(),
                    MessageTextMaxLen(),
                    MessageWordFullMatch(),
                    MessageWordStartWith(),
                    MessageWordEndWith(),
                    MessageTextFullMatch(),
                    MessageTextStartWith(),
                    MessageTextEndWith(),
                    MessageTextSubstringMatch(),
                    MessageTextRegExp(),
                    MessageTextModificationsMatch(),
                    MessageWordModificationsMatch(),
                ]).keyBy('structure.type').value()
            },
            {
                groupName: 'conditionsByMessageContent',
                values: chain([
                    Links(),
                    TelegramLinks(),
                    MessagePhoneNumberEntityRegexpType(),
                    MessageMentionEntityRegexpType(),
                    MessageTextMentionEntityRegexpType(),
                    TelegramLinkDescriptionContainsLink(),
                    TelegramLinkDescriptionSubstringMatch(),
                    TelegramLinkDescriptionContainsTgLink(),
                ]).keyBy('structure.type').value()
            },
            {
                groupName: 'conditionsByDateTime',
                values: chain([
                    WorkDays(),
                    WorkTime(),
                    WorkWeekDays(),
                    WorkMonthDays(),
                    WorkDates(),
                ]).keyBy('structure.type').value()
            },
            {
                groupName: 'conditionsByUserProfile',
                values: chain([
                    UserNameFullMatch(),
                    UserNameMaxLen(),
                    UserNameMinLen(),
                    UserNameSubstringMatch(),
                    UserNameRegExp(),
                    UserLoginFullMatch(),
                    UserLoginMaxLen(),
                    UserLoginMinLen(),
                    UserLoginRegExp(),
                    UserDescriptionSubstringMatch(),
                    UserDescriptionContainsLink(),
                    UserDescriptionContainsTgLink(),
                ]).keyBy('structure.type').value()
            },
            {
                groupName: 'conditionsExtra',
                values: chain([
                    MessageReplyState(),
                    MessageType(),
                    MyRights(),
                    UserRights(),
                    ReplyUserRights(),
                ]).keyBy('structure.type').value()
            },

        ]
    }
}