import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { baseCondition } from "@/components/TriggerSetup/logic/structures/base-condition.structure";
import { TriggerCondition } from "@/components/TriggerSetup/logic/types/types";
import WorkDatesSetupView from "@/components/TriggerSetup/conditions/WorkDates/WorkDatesSetupView.vue";
import { snakeCase } from "lodash";
import { getTagsByFieldKey } from '@/mixins/TariffsTagsHelper'

const condition: () => EntityData = () => {
  return {
    'setupView': WorkDatesSetupView,
    'structure': {
      'type': 'WorkDates',
      ...baseCondition,
    } as TriggerCondition,
    'titleKey': `trigger_condition_${ snakeCase('WorkDates') }`,
    'limited': getTagsByFieldKey('WorkDatesCondition')
  }
}

export default condition
