import { EntityData } from '@/components/TriggerSetup/logic/types/entity-data.type'
import { snakeCase } from 'lodash'
import SendLogMessageActionSetupView from './SendLogMessageActionSetupView.vue'
import { getTagsByFieldKey } from '@/mixins/TariffsTagsHelper'
import i18n from '@/i18n/i18n'

const action: () => EntityData = () => {
    return {
        'setupView': SendLogMessageActionSetupView,
        'structure': {
            'type': 'SendLogMessageAction',
            'chance': 100,
            'target': '',
            'text': [ {
                'text': '%profile% %trigger%\n\n%chat%\n\n%message%',
            } ],
        },
        'titleKey': `trigger_action_${ snakeCase('SendLogMessageAction') }`,
        'limited': getTagsByFieldKey('SendLogMessageAction'),
    }
}

export default action
