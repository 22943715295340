


















import { Component, Mixins } from 'vue-property-decorator'
import ActionChance from '@/components/TriggerSetup/components/ActionChance/ActionChance.vue'
import { ActionSetupView } from '@/components/TriggerSetup/logic/mixins/action-setup-view.mixin'
import { TriggerInputsSetups } from '@/components/TriggerSetup/logic/mixins/mixins'
import ForwardMessageActionsWarning from '@/components/TriggerSetup/components/ForwardMessageActionsWarning.vue'

@Component({
  'components': {
    ForwardMessageActionsWarning,
    ActionChance
  }
})
export default class ForwardMessageToTargetActionSetupView extends Mixins<ActionSetupView, TriggerInputsSetups>(ActionSetupView, TriggerInputsSetups) {

}
