














import ConditionUserRights from '@/components/TriggerSetup/components/ConditionUserRights/ConditionUserRights.vue'
import ConditionInvertSettings
  from '@/components/TriggerSetup/components/ConditionInvertSettings/ConditionInvertSettings.vue'
import { ConditionSetupView } from '@/components/TriggerSetup/logic/mixins/condition-setup-view.mixin'
import Component from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'

@Component({
  components: {
    ConditionInvertSettings,
    ConditionUserRights
  }
})
export default class ReplyUserRights extends Mixins<ConditionSetupView>(ConditionSetupView) {
}
