













































































































































import { getTariffTranslationKey } from '@/includes/helpers/tariffHelper'
import NotAvailableOptionsOverlay from '@/components/NotAvailableOptionsOverlay.vue'
import { TriggerCondition, TriggerData, IterableListItem } from '@/components/TriggerSetup/logic/types/types'
import { TriggerFactory } from '@/components/TriggerSetup/logic/factories/trigger-factory'
import ConditionsGroups from '@/components/TriggerSetup/components/ConditionsGroups/ConditionsGroups.vue'
import ActionsGroups from '@/components/TriggerSetup/components/ActionsGroups/ActionsGroups.vue'
import { TriggerControlsMixin } from '@/components/TriggerSetup/logic/mixins/trigger-controls.mixin'
import { InputSetups } from '@/mixins/input-setups'
import { TriggerHelper } from '@/components/TriggerSetup/logic/mixins/mixins'
import ConditionsHelper from '@/components/TriggerSetup/conditions'
import ActionsHelper from '@/components/TriggerSetup/actions'

import Accordion from 'piramis-base-components/src/components/Accordion/Accordion.vue'
import { UseFields } from 'piramis-base-components/src/components/Pi'
import { ListDirection } from 'piramis-base-components/src/components/Pi/components/List/logic/types'

import { Component, Mixins, VModel, Prop } from 'vue-property-decorator'
import { isEmpty, snakeCase } from 'lodash'

@Component({
  'components': {
    ConditionsGroups,
    ActionsGroups,
    Accordion,
    NotAvailableOptionsOverlay
  },
  data() {
    return {
      ListDirection,
      getTariffTranslationKey
    }
  },
  'methods': {
    snakeCase
  }
})
export default class TriggerSetup extends Mixins<UseFields, InputSetups, TriggerHelper, ConditionsHelper, ActionsHelper>(UseFields, InputSetups, TriggerHelper, ConditionsHelper, ActionsHelper) {
  @VModel({ 'type': Object }) triggerData!: TriggerData

  @Prop({ 'required': true }) readonly controls!: { view: typeof TriggerControlsMixin, props: Record<string, any>, listeners: Record<string, any> }

  @Prop({ 'type': Boolean, 'default': false }) readonly disabled!: boolean

  triggerFactory: TriggerFactory = new TriggerFactory()

  isExtraSettingsOpen = false

  validTrigger: Record<string, boolean> = {
    trigger_validate_name: false,
    trigger_validate_conditions: false,
    trigger_validate_conditions_values: false,
    trigger_validate_actions: false,
    trigger_validate_actions_values: false,
  }

  get checkValidTrigger(): Array<string> {
    let notValidFields: Array<string> = []

    Object.entries(this.validTrigger)
      .forEach(([ fieldKey, fieldValue ]) => {
        if (!fieldValue) {
          notValidFields.push(fieldKey)
        }
      })
    return notValidFields
  }

  get isTriggerValid(): boolean {
    const trigger: any = this.triggerData

    if (!isEmpty(trigger)) {
      const name = trigger.name
      const conditions = trigger.conditions
      const actions = trigger.actions

      this.validTrigger.trigger_validate_name = !!name.trim()

      //check if at least 1 condition exists
      this.validTrigger.trigger_validate_conditions = conditions[0] && conditions[0].value[0]

      //check if at least 1 action exists
      this.validTrigger.trigger_validate_actions = actions[0] && actions[0].value.actions.length

      // check condition values are set
      const isInvalidConditionsList = () => {
        let isEmptyConditionsValues = false

        if (conditions.length > 0 && conditions[0].value[0] && conditions[0].value[0].value) {
          conditions.forEach((val: any) => {
            val.value.forEach((item: any) => {
              if (!item.value.value.length) {
                isEmptyConditionsValues = true
              }
            })
          })
          return isEmptyConditionsValues
        }
      }
      this.validTrigger.trigger_validate_conditions_values = !isInvalidConditionsList()

      //check if action fields are not empty
      const isInValidActionsList = () => {
        let isInvalidActions: Array<boolean> = []

        if (trigger.actions.length !== 0 && trigger.actions.some((group: any) => group.value.actions.length !== 0)) {
          trigger.actions.forEach((actionsGroup: any) => {
            actionsGroup.value.actions.forEach((action: any) => {
              isInvalidActions.push(this.checkAction(action.value))
            })
          })
        } else {
          isInvalidActions.push(false)
        }
        return isInvalidActions.some(item => !item)
      }

      this.validTrigger.trigger_validate_actions_values = !isInValidActionsList()
    }

    return Object.values(this.validTrigger).includes(false)
  }

  get groupSendInterval(): string | null {
    if (this.$store.state.userState.currentUserConfig) {
      const limitTime = this.$store.state.userState.currentUserConfig.limits.group_send_interval / 60
      return `${ limitTime } ${ this.$t(`group_send_interval_${ limitTime }`).toString() }`
    }
    return null
  }

  get sendInterval(): string | null {
    if (this.$store.state.userState.currentUserConfig) {
      const limitTime = this.$store.state.userState.currentUserConfig.limits.send_interval / 60
      return `${ limitTime } ${ this.$t(`send_interval_${ limitTime }`).toString() }`
    }
    return null
  }

  checkAction(action: any): boolean {
    let isInvalid = false
    const actionsWithMessage = [ 'SendMessageToCalculatedTargetAction', 'SendLogMessageAction', 'SendMessageToChatAction', 'SendMessageToTargetAction' ]
    const targetTypeActions = [ 'SendMessageToCalculatedTargetAction', 'ForwardReplyMessageToCalculatedTargetAction', 'ForwardMessageToCalculatedTargetAction' ]

    if (targetTypeActions.includes(action.type)) {
      isInvalid = !!action.targetType
    }

    if ([ 'ForwardReplyMessageToTargetAction', 'ForwardMessageToTargetAction' ].includes(action.type)) {
      isInvalid = !!action.target
    }

    if (actionsWithMessage.includes(action.type)) {
      isInvalid = this.checkTextAction(action.text)
    }

    if (action.type === 'RegisterEventAction') {
      isInvalid = action.weight !== 0 || !action.eventId
    }

    return isInvalid
  }

  checkTextAction(list: any): boolean {
    let textsChecks: Array<boolean> = []
    list.forEach((textItem: any) => {
      textItem.text.trim() ? textsChecks.push(true) : textsChecks.push(false)
    })
    return !textsChecks.some((element: boolean) => !element)
  }

  removeOverflowAccordion(): void {
    setTimeout(() => {
      const element = document.getElementsByClassName('accordion__content')[0] as HTMLElement
      element.style.overflow = 'visible'
    }, 10)
  }

  get conditionsGroups(): Array<IterableListItem<Array<IterableListItem<TriggerCondition>>>> {
    return this.triggerData.conditions
  }

  set conditionsGroups(value: Array<IterableListItem<Array<IterableListItem<TriggerCondition>>>>) {
    this.triggerData.conditions = value
  }

  get actionGroups(): any {
    return this.triggerData.actions
  }

  set actionGroups(value: any) {
    this.triggerData.actions = value
  }

  get isDisabled(): boolean {
    return this.disabled
  }

  get areExtraSettingsEnabled(): boolean {
    return Boolean(this.triggerData.chance !== 100)
  }

  mounted(): void {
    if (this.areExtraSettingsEnabled) {
      this.isExtraSettingsOpen = true
      this.removeOverflowAccordion()
    }
  }
}
