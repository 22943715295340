import { Component, Mixins, Prop, VModel } from 'vue-property-decorator'
import { UseFields } from 'piramis-base-components/src/components/Pi/index'
import { TriggerHelper } from '@/components/TriggerSetup/logic/mixins/mixins'
import { SelectOptionData } from 'piramis-base-components/src/components/Pi/types'
import { cloneDeep } from 'lodash'

@Component
export class ActionSetupView extends Mixins<UseFields, TriggerHelper>(UseFields, TriggerHelper) {
    @VModel({ 'type': Object }) model!: any

    @Prop({ 'type': Boolean, 'default': false }) readonly disabled!: boolean

    targets(): Promise<Array<SelectOptionData>> {
        return Promise.resolve(this.getActionTarget(this.model.type))
    }

    calculatedTargetTypes(): Promise<Array<SelectOptionData>> {
        return Promise.resolve(this.getCalculatedTargetTypes)
    }

    asReplyTypesOptions(): Promise<Array<SelectOptionData>> {
        return Promise.resolve(this.asReplyTypes)
    }

    configEventOptions(): Promise<Array<SelectOptionData>> {
        const events = cloneDeep(this.$store.state.userState.currentUserConfig?.config.events)
        const options = Object.entries(events).map(([ k, v ]) => {
            return {
                label: k,
                value: v
            }
        })
        return Promise.resolve(options)
    }
}
