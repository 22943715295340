import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import WorkDaysSetupView
  from "@/components/TriggerSetup/conditions/WorkDays/WorkDaysSetupView.vue";
import { baseCondition } from "@/components/TriggerSetup/logic/structures/base-condition.structure";
import { TriggerCondition } from "@/components/TriggerSetup/logic/types/types";
import { snakeCase } from "lodash";
import { getTagsByFieldKey } from '@/mixins/TariffsTagsHelper'

const condition: () => EntityData = () => {
  return {
    'setupView': WorkDaysSetupView,
    'structure': {
      'type': 'WorkDays',
      ...baseCondition,
      ...{ 'value': [ 1, 30 ] }
    } as TriggerCondition,
    'titleKey': `trigger_condition_${ snakeCase('WorkDays') }`,
    'limited': getTagsByFieldKey('WorkDaysCondition')
  }
}

export default condition
