














import { Component, Mixins } from 'vue-property-decorator'
import { ActionSetupView } from "@/components/TriggerSetup/logic/mixins/action-setup-view.mixin";
import { TriggerInputsSetups } from '@/components/TriggerSetup/logic/mixins/mixins'

@Component
export default class ActionFormButtonAction extends Mixins<ActionSetupView, TriggerInputsSetups>(ActionSetupView, TriggerInputsSetups) {

}
