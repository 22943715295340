var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"action-text_wrapper"},[_c('multi-message-editor-with-media-input',{attrs:{"setup":{
      'func': _vm.triggerInputSetupFn,
      'args': {
        model: _vm.model,
        'key': 'text',
        'prefix': 'trigger_action_',
        disabled: _vm.disabled,
        'validation': 'required',
        'targetToUpload': function () {
          return {
            'target': 'user',
            'target_id': _vm.$store.state.userState.currentUserConfig.user.key
          }
        },
        'hasMedia': false,
        'editorMode': _vm.MediaEditorMode.TelegramShort,
        'base-api-url': 'api.tgscout.app/cabinet',
        'availableTabs': [_vm.MessageEditorWithMediaTab.TextareaTab, _vm.MessageEditorWithMediaTab.EditorTab],
        'placeholders': _vm.BASE_PLACEHOLDERS
      }
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }