import { EntityData } from '@/components/TriggerSetup/logic/types/entity-data.type'
import { snakeCase } from 'lodash'
import SendMessageToChatActionSetupView
    from '@/components/TriggerSetup/actions/SendMessageToChatAction/SendMessageToChatActionSetupView.vue'
import { getTagsByFieldKey } from '@/mixins/TariffsTagsHelper'
import i18n from '@/i18n/i18n'

const action: () => EntityData = () => {
    return {
        'setupView': SendMessageToChatActionSetupView,
        'structure': {
            'type': 'SendMessageToChatAction',
            'chance': 100,
            'asReply': 'None',
            'text': [ {
                'text': '',
                'buttons': [],
                'remove_after': 0,
                'send_after': 0,
                'pin': false,
                'disable_link_preview': false,
                'disable_notify': false,
                'remove_previous': false
            } ],
        },
        'titleKey': `trigger_action_${ snakeCase('SendMessageToChatAction') }`,
        'limited': getTagsByFieldKey('SendMessageToChatAction'),
}
}

export default action
